.date-events {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    padding: 0% 2% 0% 2%;
}

.date-title {
    font-size: large;
    color: #480909;
}

.carousel {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    column-gap: 15px;

    min-height: 180px;
    overflow-x: scroll;
}
@media screen and (max-width: 600px) {
    .carousel {
        scrollbar-width: none; /* Hide scrollbar on Firefox */
        -ms-overflow-style: none; /* Hide scrollbar on Edge */
    }
    .carousel::-webkit-scrollbar {
        display: none; /* Hide scrollbar on Chrome and Safari */
    }
}  

.carousel-item {
    display: flex;
    flex-flow: column;
    row-gap: 8px;

    min-width: 120px;
    max-width: 120px;
    padding: 0.8%;
    background-color: antiquewhite;
    cursor: pointer;
}
@media screen and (max-width: 600px) {
    .carousel-item {
        padding: 2%;
    }
}  

.event-image {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: black;
}
.event-image img {
    width: 100%;
}

.event-location {
    font-weight: bold;
}