.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-shadow-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 120%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
}
  
.event-modal {
    display: flex;
    flex-flow: column;
    row-gap: 4px;

    background-color: antiquewhite;
    padding: 2%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
}
@media screen and (max-width: 600px) {
    .event-modal {
        padding: 4%;
    }
}

.event-modal-image img {
    width: 100%;
}
/*@media screen and (max-width: 600px) {
    .event-modal-image img {
        max-height: 400px;
    }
}
@media screen and (min-width: 601px) and (max-width: 1300px) {
    .event-modal-image img {
        max-height: 480px;
    }
}
@media screen and (min-width: 1301px) {
    .event-modal-image img {
        max-height: 600px;
    }
}*/

.event-modal-name {
    font-weight: bold;
}