.header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    background-color: antiquewhite;
    padding: 40px 0 40px 0;
    /* border-bottom: 1px solid black; */
    margin-bottom: 20px;
}

.title-div {
    font-size: xx-large;
    color: #A00;
}

.second-title-div {
    font-style: italic;
    font-size: smaller;
    color: #480909;
}